(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

$(document).ready(function () {

	$('.login-trigger').click(function (e) {
		e.preventDefault();
		$('.modal-login').fadeIn(300);
		$('.modal-password').fadeOut(300);
	});

	$('.password-trigger').click(function (e) {
		e.preventDefault();
		$('.modal-password').fadeIn(300);
		$('.modal-login').fadeOut(300);
	});

	$('.modal-overlay').click(function (e) {

		if ($(e.target).is('div.modal-overlay')) {
			$('.modal-login, .modal-password').fadeOut(300);
		}
	});
});

},{}]},{},[1]);
